.pin-confirm-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 47%;
}
.close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border-radius: 7px;
  border: 2px solid black;
  font-size: 2rem;
  color: black;
  cursor: pointer;
}
.close-button:hover {
  background: grey;
}
.input-field {
  width: 80px;
  height: 60px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 7px;
  text-align: center;
  font-size: 25px;
  outline: none;
  caret-color: transparent;
}
.input-field:focus {
  border-color: #4caf50;
  box-shadow: 0 0 5px #4caf50;
}
.submit-button {
  width: 150px;
  padding: 10px;
  color: white;
  background-color: #4caf50;
  border: 1px solid black;
  border-radius: 7px;
  font-size: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049;
}
.confirmbutton {
  width: 150px;
  border-radius: 7px;
  margin-top:20px;
}

.pin-confirm-overlay2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding-bottom: 100px;
  height: 47%;
}

.qrbuttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 200px;
}

.confirmation-container {
  margin-top: 20px;
  text-align: center;
}
.confirmation-icon {
  font-size: large;
  margin-right: 20px;
}

.czy {
  margin-top: 50px;
  text-align: center;
}

.nieposiadasz {
  text-align: center;
}

@media (max-width: 768px) {
  .qrbuttons {
    width: 90%;
  }
  .qrbuttons button {
    height: 100px;
  }
  .pin-confirm-overlay2 {
    margin-top: 150px;
  }


  .confirmbutton {
    width: 90%;
    height: 100px;
    border-radius: 7px;
    margin-top:20px;
    font-size: 30px;
  }
  
}
