.container3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}
.textcontainer {
  margin-bottom: 20px;
}
h2 {
  font-size: 24px;
  color: #333;
  margin: 0;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.input-container {
  display: flex;
  padding-top: 20px;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.error-message {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color: red;
  font-weight: bold;
  display: inline-block;
}

.visible {
  opacity: 1;
}

.input-field2 {
  width: 80px;
  height: 60px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 7px;
  text-align: center;
  font-size: 25px;
  outline: none;
  caret-color: transparent;
}
.input-field2:focus {
  border-color: #4caf50;
  box-shadow: 0 0 5px #4caf50;
}
.submit-button {
  margin-top: 20px;
  width: 150px;
  height: 67px;
  margin-left: 20px;
  color: white;
  background-color: #4caf50;
  border: 1px solid black;
  border-radius: 7px;
  font-size: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049;
}
.input-field2::selection {
  background-color: transparent;
}
