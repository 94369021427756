.pin-confirm-overlay2
{
  margin-top:300px;
}
.confirmation-container2 {
    z-index: 1000;
    position: fixed;
    top: 180px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50%;
  }


  .text-center
  {
    text-align: center;
  }

  .qrbuttons
  {
    text-align: center;
margin:auto;
margin-top:20px;
  }
  .today
  {
    margin:0;
    padding:0;
    margin-bottom:50px;
  }