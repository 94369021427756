@font-face {
  font-family: Poppins;
  src: url(../fonts/Poppins-Light.ttf);
}

.top {
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #e9ecef;
  color: black;
  width: 100%;
}

.title-text {
  display: flex;
  flex-direction: column;
  line-height: 1;
  align-items: center;
}

.title-text .title span {
  display: inline;
}

.logo {
  width: 57px;
  margin-right: 40px;
}

@media only screen and (max-width: 600px) {
  .title-text .title span {
    display: block;
  }
}
