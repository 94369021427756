
.HomePageContainer {
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  height: 100%;
  gap: 10px;
}
.desk.reserved {
  background: linear-gradient(135deg, rgb(255, 255, 255), #ebebeb);
  color: grey;
  border: 2px solid #c7c7c7;
  cursor: not-allowed;
  transition: none;
}
.cancel-reservation {
  font-weight: bold;
  background: none;
  border-radius: 7px;
  color: black;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 40px;
}

.zarezerwowane-miejsce {
  border-right: 2px solid #c7c7c7;
}
.confirm-reservation {
  width: 40px;

  font-weight: bold;
  background: none;
  border-radius: 7px;
  color: black;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.smallletters {
  font-size: 10px;
}
.cancel-reservation:hover {
  background-color: grey;
}
.confirm-reservation:hover {
  background-color: grey;
}
.logout-button {
  position: absolute;
  top: 165px;
  right: 5px;
  border-radius: 7px 7px 0 0;
  background: none;
  color: black;
  background: white;
}
.logout-button:hover {
  background: grey;
}

.adminpanel-button {
  position: absolute;
  top: 165px;
  right: 100px;
  border-radius: 7px 7px 0 0;
  background: none;
  color: black;
  background: white;
}

.adminpanel-button:hover {
  background: grey;
}

.desk.reserved:hover {
  cursor: not-allowed;

  transform: none;
}

.reserved-info {
 width: 90%;
 max-width: 500px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #f5f5f5, #dcdcdc);
  border: 2px solid #999999;
  border-radius: 7px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  margin:auto;
  margin-top:10px;
  margin-bottom:40px;


  color: #333333;
}

.reserved-confirmed {
  color: rgb(49, 197, 49);
}

.info-item {
  
  width:100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 7px;
  gap: 15px;
}

.info-icon {
  width: 50px;
  height: 50px;
}

.info-title {
  width: 250px;
  font-weight: 600;
  padding-top: 10px;
}

.info-description {
  width: 250px;
  color: #666666;
  padding-bottom: 10px;
  padding-left: 20px;
}

.desk-container {
  display: flex;
  width: 90%;
  margin-bottom: 10px;
  max-width: 500px;
}

.desk {
  display: flex;
  flex-grow: 1;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;

  background: linear-gradient(135deg, #f5f5f5, #dcdcdc);
  border: 2px solid #999999;

  width: 90%;
  height: 100px;

  border-radius: 8px;
  transition: all 0.3s ease;

  transition: background 0.3s ease, box-shadow 0.3s ease;
  transition: all 0.3s ease;
}
.reservation-btn {
  width: 0;
  overflow: hidden;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  text-align: center;
  padding: 0;
}
.date-input {
  font-family: Poppins;
  width: 200px;
  padding: 10px;
  border-radius: 7px;
  font-size: 18px;
  background: rgb(182, 181, 181) 35%;
  color: black;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 0 7px 7px 0;
  font-weight: bold;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  padding: 20px;
  margin:auto;
  text-align: center;
  border: 1px solid #888;
  width: 80%;
}

.reserve-container {
  display: flex;
  justify-content: flex-end;
}
.pin-confirm-overlay {
  position: fixed;
  top: 13%;
  bottom: 30%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.confirmation-modal {
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.reservation-btn.visible {
  width: 100px;
  padding: 0 10px;
}
.reservation-btn:hover {
  background-color: #0056b3;
}

.desk.selected .reservation-btn {
  display: inline-block;
}
.desk:hover {
  transform: scale(1.02);
  cursor: pointer;
}
.desk.selected {
  border-radius: 7px 0 0 7px;

  background: linear-gradient(
    90deg,
    rgb(182, 181, 181) 35%,
    rgb(194, 193, 193) 100%
  );
  box-shadow: 0 0 0 2px black;
}

.desk-name {
  position: absolute;
  top: 10px;
  left: 10px;
}

.desk-number {
  font-size: 30px;
  font-weight: bold;
}
.desk-did {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 11px;
}
.desk-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  margin: 10px;

}
.geet
{
  background-image: url(../../../public/sitting-table-icon.svg);
  background-repeat: no-repeat;
  opacity: 0.2;

  width:100px;
  height:100px;
}

.geet2
{
  background-image: url(../../../public/parking-1641.svg);
  background-size:100%;
  background-repeat: no-repeat;
  opacity: 0.2;
  width:100px;
  height:100px;
}
.geet3
{
  background-image: url(../../../public/sitting-table-icon.svg);
  background-size:100%;
  background-repeat: no-repeat;
  width:100px;
  height:50px;
  margin-top:auto;
  margin-left:20px;
}

.geet4
{
  background-image: url(../../../public/parking-1641.svg);
  background-size:100%;
  background-repeat: no-repeat;
  width:100px;
  height:50px;
  margin-top:auto;
  margin-left:20px;
}


button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 0 5px 5px 0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.blocked {
  margin-top: 100px;
  font-size: 15pt;
  text-align: center;
}
.forSomeone {
  width: 345px;
  display: flex;
  height: auto;
  color:black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.paddingg
{
  padding:20px;
}

.pasek {
  display:flex;
  justify-content: center;
  align-items: center;
  width: 355px;
  margin: auto;

}
.forSomeone select {
  border-radius: 0 0 7px 7px;
  background: rgb(182, 181, 181) 35%;
  width: 355px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.reserve-for {

  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 7px;
  font-size: 14px;
  background: rgb(182, 181, 181) 35%;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 7px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.reserve-for-active {
  border-radius: 7px 7px 0 0;
}

.reserve-for:hover {
  cursor: pointer;
  background-color: #666666;
}

.choose-date {
  width: 200px;
  padding: 11px;
  border-radius: 7px;
  background: rgb(182, 181, 181) 35%;
  color: black;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 7px 0 0 7px;
  border-right: 2px solid grey;
  font-weight: bold;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.margin-auto {
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  width:100%;
  gap:20px;
}

@media (max-width: 768px) {
  .HomePageContainer {
    margin-top: 5%;
  }

  .desk {
    height: auto;
  }

  .modal-content {
    width: 90%;
  }
.choose-date
{
  height: 20px;
}
  .date-input {
    padding-bottom: 10px;
    height: 42px 

}

  .reservation-btn.visible {
    padding: 0 10px;
  }

  .logout-button {
    right: 0;
  }
 

  .info-title {
    padding-left: 0;
    width:90%;
  }

  .cancel-reservation {
    margin-right: 5px;
  }


  .info-title {
    width:80%;
    font-size: 14px;
  }
  
  .info-description {
    padding-left: 0;
  }

  .margin-auto
  {
    min-width:50px;
  }


  .geet
{
  background-image: url(../../../public/sitting-table-icon.svg);
  background-repeat: no-repeat;
  opacity: 0.2;

  width:50px;
  height:50px;
}

.geet2
{
  background-image: url(../../../public/parking-1641.svg);
  background-size:100%;
  background-repeat: no-repeat;
  opacity: 0.2;
  width:50px;
  height:50px;
}



  
}

@media (max-width: 480px) {
  .choose-date {
    width: 150px;
  }

  .date-input {
    width: calc(100% - 160px);
    height: 20px;
    
  }
 .margin-auto
 {
  gap:0;
 }
  .info-description {
    padding-left: 0;
    width:160px;

  }
  .info-title {
    padding-left: 0;
    width:160px;
  }
}
.green
{
  background-image: linear-gradient(
    50deg,
    hsl(0deg 0% 96%) 0%,
    hsl(93deg 24% 92%) 13%,
    hsl(94deg 32% 88%) 27%,
    hsl(94deg 36% 84%) 42%,
    hsl(94deg 38% 80%) 56%,
    hsl(95deg 40% 76%) 68%,
    hsl(95deg 41% 72%) 78%,
    hsl(96deg 41% 68%) 87%,
    hsl(96deg 42% 65%) 94%,
    hsl(97deg 42% 61%) 100%
  );
}
