.user-edit-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    background-color: #f5f5f5;
    font-family: Poppins;
}

.input-group {
    margin-bottom: 25px;
}


.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
.input-group label {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
    color: #333;
}
.title
{
    margin-bottom:20px;
}

.input-group input[type="text"], 
.input-group input[type="number"],
.input-group input[type="date"] {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
select
{
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}


.input-group input[type="text"]:hover,
.input-group input[type="number"]:hover,
.input-group input[type="date"]:hover {
    border-color: #bdbdbd;
}

.input-group input[type="text"]:focus,
.input-group input[type="number"]:focus,
.input-group input[type="date"]:focus {
    border-color: #007BFF;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
}

input-group input[type="checkbox"] {
    margin-right: 12px;
    padding-top:100px;
}


button {
    padding: 12px 25px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.backbutton
{
    display:flex;
    align-items: center;
    cursor:pointer;
    border:2px solid grey;
    border-radius: 7px;
    width:50px;
    height:50px;
    background: linear-gradient(135deg, #f5f5f5, #dcdcdc); 
    font-size: 20px;
    justify-content: center;
    margin-left:50px;
    margin-top:10px;
    color:grey;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}
.backbutton:hover
{
    transform: translateY(-2px);

    background: linear-gradient(135deg, #999999, #636363); 

}
.testt
{
    width:100%;
}
.delete-user
{
    position:relative;

    margin-left:5px;
    padding:5px 10px 5px 10px;
    background-color: #333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}

.delete-user:hover {
    transform: translateY(-2px);

    background: #555; /* ciemniejsze tło dla efektu wciśnięcia */


}
button{
    background-color: #333;

}
button:hover {
    background: #555; /* ciemniejsze tło dla efektu wciśnięcia */


}
.temp
{
    margin-top:20px;
}

input, select {
    box-sizing: border-box;
}
