.logout-button {
  position: absolute;
  top: 162px;
  right: 5px;
  box-shadow: none;
  border-radius: 7px 7px 0 0;
  background: none;
  color: black;
  background: white;
}
.logout-button:hover {
  background: grey;
}

.adminpanel-button {
  position: absolute;
  box-shadow: none;
  top: 162px;
  right: 100px;
  border-radius: 7px 7px 0 0;
  background: none;
  color: black;
  background: white;
}

.home-button {
  position: absolute;
  box-shadow: none;

  top: 162px;
  right: 100px;
  border-radius: 7px 7px 0 0;
  background: none;
  color: black;
  background: white;
}

.qr-button {
  position: absolute;
  box-shadow: none;

  top: 162px;
  right: 190px;
  border-radius: 7px 7px 0 0;
  background: none;
  color: black;
  background: white;
}


.adminpanel-button:hover {
  background: grey;
}
.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 100%;
  margin-bottom:100px;
}

.HomePageContainer {
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  height: 100%;
  gap: 10px;
}

.admin-menu {
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 200px;
  bottom:0;
  height: 100vh;
  width: 200px;
  padding: 10px;
  background-color: #e9ecef;
}


.admin-menu button {
  width: 100%;
  border: none;
  border-radius: 7px;
  background: #333;
  color: #fff;
  margin-bottom: 10px;
  font-weight: bold;
  transition: all 0.1s ease;
  display: flex;
  align-items: center;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  justify-content: left;
  padding: 15px 25px;
}

.admin-menu-icon {
  margin-right: 20%;
  display: inline-flex;
  align-items: center;
}
.admin-menu button:hover {
  background: #555;
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.admin-menu button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-menu-button-selected {
  background: #ff6b6b;
  color: #fff;
}

.gradient-table {
  margin: auto;
  margin-left: 300px;
  background: linear-gradient(to bottom, #f7f7f7, #e5e5e5);
  border-collapse: collapse;
  width: 80%;
}

.gradient-table th,
.gradient-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.gradient-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.gradient-table th {
  background-color: #d6d6d6;
  color: black;
}
.wiersz:hover {
  background-color: #919191 !important;
  cursor: pointer;
}

@media (max-width: 768px) {
  .admin-menu {
    width: 50px;
  }

  .admin-menu button {
    text-indent: -9999px;
    justify-content: center;
  }
  .admin-menu button .icon {
    margin-right: 0;
  }
}

.add-user {
  margin-left: 20px;
  background-color: #333;
}
.add-user:hover {
  background: #555;
}

.summary-container {
  display: flex;
  margin-left: 300px;
  flex-wrap: wrap;
  gap: 16px;
}

.tile {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  position: relative;
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
  height: 250px;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: height 0.3s ease-in-out;
  height: 150px;
}

.tile:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.tileData {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
  text-align: center;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  text-align: center;
}

th {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tile.active {
  height: auto;
  padding-bottom: 40px;
  max-height: 500px;
}
.arrow {
  position: absolute;
  padding-top: 5px;
  left: 16px;
  right: 16px;
  text-align: center;
  height: 0px;
  bottom: 30px;
}

.tile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.tile-text {
  font-size: 16px;
  color: grey;
  margin-bottom: 20px;
}

.tile-number {
  font-size: 35px;
  font-weight: bold;
}

.gorne2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.gorne2 .tile {
  flex: 1;
  margin-right: 16px;
}

.gorne2 .tile:last-child {
  margin-right: 0;
}

.resztaKafelkow {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.resztaKafelkow .tile {
  width: calc(33.33% - 43px);
}
.admindate {
  margin-right: 20px;
}
.noPermissions {
  margin-top: 100px;
  font-size: 40pt;
  text-align: center;
  width: 100%;
}
.miejsce {
  width: 200px;
  background: rgb(182, 181, 181) 35%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.top-admin-bar {
  margin: auto;
}

@media (max-width: 768px) {
  .summary-container {
    margin-left: 100px;
  }

  .tile-text {
    font-size: 6pt;
  }

  .pasek {
    margin-top: 50px;
  }
  .date-input {
    padding-bottom: 10px;
    height: 42px;
  }

}

@media (max-width: 480px) {
  .choose-date {
    width: 150px;
  }

  .date-input {
    width: calc(100% - 160px);
  }
}
