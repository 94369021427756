.confirmation-container {
  z-index: 1000;
  position: fixed;
  top: 11%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70%;
}

.close-button2 {
    margin-top: 100px;
    width: 300px;
    font-size: 30px;
    height: 70px;
  }

  .confirmation-container:focus {
    outline: none;
}

.message
{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}



@media (max-width: 768px) {
  .confirmation-container {
    height: 71%;

    top: 12%;
  }
  .close-button2 {
    margin-top: 100px;
    width: 90%;
    height: 100px;
  }
}
